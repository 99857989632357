<template>
  <div id="orders-view" class="data-list-container">
    <h2 class="mt-n6 mb-6 title-h2">{{ pageSubtitle }}</h2>
    <div v-if="orders">
      <hx-table
          ref="table"
          :data="orders"
          :max-items="itemsPerPage"
          :noDataText="getNoDataText()"
          pagination
          v-on:selected="onOrderClick"
      >
        <template slot="thead">
          <hx-th sort-key="residentCivility">Civilité</hx-th>
          <hx-th sort-key="residentName">NOM et Prénom</hx-th>
          <hx-th sort-key="productCount">Produits{{ isPriceAlertIsVisible ? ' *' : '' }}</hx-th>
          <hx-th sort-key="serviceCount">Services{{ isPriceAlertIsVisible ? ' *' : '' }}</hx-th>
          <hx-th
              v-if="$acl.check('isEhpadManager')"
              sort-key="tutorName"
          >Référent
          </hx-th>
          <hx-th sort-key="price">Prix{{ isPriceAlertIsVisible ? ' *' : '' }}</hx-th>
        </template>
        <template slot-scope="{data}">
          <tbody>
          <vs-tr
              v-for="(order, orderIndex) in data"
              :key="orderIndex"
              :data="order"
          >
            <vs-td>
              <p class="resident-civility tw-font-bold">{{ order.residentCivility }}</p>
            </vs-td>
            <vs-td>
              <p class="resident-name tw-font-bold">{{ order.residentName }}</p>
            </vs-td>
            <vs-td>
              <p class="order-product-count">{{ order.productCount }}</p>
            </vs-td>
            <vs-td>
              <p class="order-service-count">{{ order.serviceCount }}</p>
            </vs-td>
            <vs-td v-if="$acl.check('isEhpadManager')">
              <p class="resident-tutor">{{ order.tutorName }}</p>
            </vs-td>
            <vs-td>
              <p class="order-amount">{{ order.amount }} €</p>
            </vs-td>
          </vs-tr>
          </tbody>
        </template>
      </hx-table>
    </div>
    <HAlert
        class="price-alert"
        v-if="isPriceAlertIsVisible"
        dense
        text
        type="info"
    >
        * Les quantités et les montants affichés sont indicatifs et
        ne prennent pas en compte la disponibilité des produits
        et services. Cliquez sur la ligne correspondant à votre
        résident pour accéder à la composition à jour
        de sa prochaine commande.
    </HAlert>
  </div>
</template>

<script>
import _ from 'lodash'
import async from 'async'

import Routes from '@/constants/routes'
import {getGenderLabel} from '@/modules/labels'

import {
  HAlert
} from '@happytal/bo-ui-library'

export default {
  props: [
    'status'
  ],
  components: {
    HAlert
  },
  data() {
    return {
      loaded: false,
      orders: [],
      current: [],
      next: [],
      itemsPerPage: 6
    }
  },
  computed: {
    isPriceAlertIsVisible() {
      return this.orders && this.status == 'en-cours'
    },
    pageSubtitle() {
      switch(this.status) {
        case 'en-cours':
            return 'Commandes ouvertes'
        case 'en-livraison':
            return 'Commandes non-modifiables'
        default:
          return ''
      }
    }
  },
  methods: {
    getNoDataText() {
      switch (this.status) {
        case 'en-cours':
          return `Il n'y a actuellement aucune commande ouverte.`
        case 'en-livraison':
          return `Il n'y a actuellement aucune commande en cours de livraison.`
      }
    },
    getResidentById(residentId) {
      return this.$store.getters['dataResidents/getResidentById'](residentId)
    },
    onOrderClick(order) {
      const name = this.status == 'en-cours' ? Routes.ResidentSubscriptionsNext : Routes.ResidentHistory
      this.$router.push({
        name: name,
        params: {
          resident_id: _.get(order, 'resident.id', '')
        }
      })
    },
    /*sortOrders (key, type, orders, defSortFunc) {
        switch (key) {
            case 'resident':
                return _.orderBy(orders, [(order) => {
                    const resident = this.getResidentById(order.customerGuid)
                    return resident ? resident.lastName : ''
                }], [type])
            case 'referent':
                return _.orderBy(orders, [(order) => {
                    const tutor = _.get(order, 'resident.userInChargeOfResident', {})
                    return tutor ? tutor.lastName : ''
                }], [type])
            case 'products':
                return _.orderBy(orders, [(order) => {
                    return this.getOrderProductCountByType(order, '1')
                }], [type])
            case 'services':
                return _.orderBy(orders, [(order) => {
                    return this.getOrderProductCountByType(order, '0')
                }], [type])
            case 'price':
                return _.orderBy(orders, [(order) => {
                    return parseFloat(this.getOrderAmount(order))
                }], [type])
            default:
                return defSortFunc(orders)
        }
    },*/
    getInstitutionId() {
      return this.$store.getters['dataUser/getInstitutionId']()
    },
    getUserId() {
      return this.$store.getters['dataUser/getUserId']()
    },
    getResidentName(order) {
      const resident = _.get(order, 'resident', {})
      return this.$store.getters['dataResidents/getResidentName'](resident.id, false)
    },
    getResidentCivility(order) {
      const resident = _.get(order, 'resident', {})
      return getGenderLabel(resident.civility)
    },
    getTutorName(order) {
      const tutor = _.get(order, 'resident.userInChargeOfResident', {})
      const firstName = _.get(tutor, 'firstName', '-')
      const lastName = _.get(tutor, 'lastName', '').toUpperCase()
      return `${lastName} ${firstName}`
    },
    updateOrders() {
      this.current = this.getCurrentOrders()
      this.next = this.getNextOrders()
      this.orders = this.status == 'en-cours' ? this.next : this.current
    },
    getCurrentOrders() {
      const storeOrders = _.get(this.$store, 'state.dataOrders.orders', [])
      return _.map(storeOrders, (order) => {
        var amount = 0
        var residentCivility = this.getResidentCivility(order)
        var residentName = this.getResidentName(order)
        var tutorName = this.getTutorName(order)
        var productCount = 0
        const products = _.get(order, 'order.orderLogisticProducts', [])
        _.forEach(products, (product) => {
          const orderLines = _.get(product, 'orderContent.orderLines', [])
          amount += _.sumBy(orderLines, (orderLine) => {
            var taxes = orderLine.totalPrice
            taxes += _.sumBy(orderLine.shippingTaxes, (tax) => {
              return tax.amount
            })
            taxes += _.sumBy(orderLine.taxes, (tax) => {
              return tax.amount
            })
            productCount += orderLine.quantity
            return taxes
          })
        })
        var serviceCount = _.get(order, 'order.orderLogisticServices.length', 0)
        const services = _.get(order, 'order.orderLogisticServices', [])
        amount += _.sumBy(services, (service) => {
          return _.get(service, 'orderContent.totalPriceTTC', 0)
        })
        return {
          resident: {
            id: _.get(order, 'resident.id', ''),
            name: residentName
          },
          residentCivility,
          residentName,
          productCount,
          serviceCount,
          tutorName,
          amount: amount.toFixed(2),
          price: parseInt(amount)
        }
      })
    },
    getNextOrders() {
      const storeBaskets = _.get(this.$store, 'state.dataBaskets.user', [])
      return _.map(storeBaskets, (basket) => {

        var residentCivility = this.getResidentCivility(basket)
        var residentName = this.getResidentName(basket)
        var tutorName = this.getTutorName(basket)

        const products = _.get(basket, 'basket.basketLineProducts', [])
        const productCount = _.sumBy(products, (product) => {
          return product.quantity
        })
        const services = _.get(basket, 'basket.basketLineServices', [])
        const serviceCount = _.sumBy(services, (service) => {
          return service.quantity
        })
        const productAmount = _.get(basket, 'basket.quoteContent.totalAmountProduct', 0)
        const serviceAmount = _.get(basket, 'basket.quoteContent.totalAmountService', 0)
        var amount = productAmount + serviceAmount
        return {
          resident: {
            id: _.get(basket, 'resident.id', ''),
            name: residentName
          },
          residentCivility,
          residentName,
          productCount,
          serviceCount,
          tutorName,
          amount: amount.toFixed(2),
          price: parseInt(amount)
        }
      })
    }
  },
  watch: {
    status(val) {
      this.updateOrders()
    }
  },
  mounted() {
    async.series([
          (callback) => {
            if (!this.$store.state.dataResidents.isRegistered) {
              this.$store.dispatch('dataResidents/getResidents', {
                userId: this.getUserId(),
                institutionId: this.getInstitutionId()
              })
                  .then(() => {

                    callback(null)
                  })
              return
            }
            callback(null)
          },
          (callback) => {
            if (!this.$store.state.dataOrders.isRegistered) {
              this.$store.dispatch('dataOrders/getCurrentOrders', {
                userId: this.getUserId(),
                institutionId: this.getInstitutionId()
              })
                  .then(() => {
                    callback(null)
                  })
              return
            }
            callback(null)
          },
          (callback) => {
            if (!this.$store.state.dataBaskets.isRegistered) {
              this.$store.dispatch('dataBaskets/getUserBaskets', {
                userId: this.getUserId(),
                institutionId: this.getInstitutionId()
              })
                  .then(() => {
                    callback(null)
                  })
              return
            }
            callback(null)
          }
        ],
        (err, results) => {
          this.updateOrders()
          this.loaded = false
        }
    )
  }
}
</script>

<style lang="scss">

.price-alert {
  margin: 15px 15px 15px 15px;
  font-style: italic;
}

#orders-view {
  .vs-con-table {

    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .resident-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .05);

        td {
          padding: 20px;

          &:first-child {
            border-top-left-radius: .5rem;
            border-bottom-left-radius: .5rem;
          }

          &:last-child {
            border-top-right-radius: .5rem;
            border-bottom-right-radius: .5rem;
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }

  .vs-lg-6 {
    width: 100% !important
  }
}
</style>
